import React from 'react';
import Img from 'gatsby-image';
import addToMailchimp from 'gatsby-plugin-mailchimp';

export default class NewsletterBlock extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      message: '',
      status: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      email: e.target.value
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    const regex = /[a-z0-9._]+@[a-z0-9-]+\.[a-z]{2,3}/g;

    if(regex.test(this.state.email)) {
      const result = await addToMailchimp(this.state.email);
      console.log(result);
      this.setState({
        message: result.msg,
	status: result.result
      });
    } else {
      this.setState({
        message: 'Format incorrect',
	status: 'error' 
      });
    }
  }
  
  render(){
    return (
      <div className="newsletter-block">

        <div className="content">
          <div className="inner-container">
            <h2 dangerouslySetInnerHTML={{__html : this.props.BannerText }} />
            <div className={'message ' + this.state.status} dangerouslySetInnerHTML={{__html: this.state.message }}></div>
            <form onSubmit={this.handleSubmit}>
              <input className={'text ' + this.state.status} type='text' placeholder="votre email" value={this.state.email} name='email' onChange={this.handleChange} />
              <input className="button primary" type='submit' value="S'abonner" />
            </form>
          </div>
        </div>

        <div className="background">
          {this.props.backgroundImage &&
            <Img fluid={this.props.backgroundImage} className="background-image" alt={this.props.alt}/>
          }  
        </div>
          
      </div>
    )
  }
}
